// extracted by mini-css-extract-plugin
export var shoppingCart = "ShoppingCart-module--shoppingCart--ABcaH";
export var shoppingCartButton = "ShoppingCart-module--shoppingCartButton--PvjGR";
export var isDisabledSubmit = "ShoppingCart-module--isDisabledSubmit--P+VOI";
export var errorMessage = "ShoppingCart-module--errorMessage--7d9TF";
export var card = "ShoppingCart-module--card--qLddc";
export var cardTitle = "ShoppingCart-module--cardTitle--BM6I+";
export var priceSelected = "ShoppingCart-module--priceSelected--c6xw7";
export var shoppingCartExpandable = "ShoppingCart-module--shoppingCartExpandable--fnLzw";
export var totalPrice = "ShoppingCart-module--totalPrice--8phDF";
export var dataProtectionLegalText = "ShoppingCart-module--dataProtectionLegalText--juXE2";