import { useI18next, useTranslation } from "gatsby-plugin-react-i18next";

import { Product } from "../../features/quotes-funnel/domain/product";
import { formatCurrency } from "../../utils/currency";
import * as styles from "./TaxList.module.scss";

interface TaxListProps {
  products: Product[];
  total: string;
}

const TaxList = ({ products, total }: TaxListProps): JSX.Element => {
  const { t } = useTranslation();
  const currency = "EUR";
  const { i18n } = useI18next();

  return (
    <ul className={styles.taxList}>
      {products.map(({ name, price, taxKey }, i) => {
        const productBase = price / 1.19;
        const tax = price - productBase;
        const taxName = t(taxKey);

        return (
          <li key={i}>
            <h3 className={styles.taxItemTitle}>{name}</h3>
            <dl>
              <dt>{t("details.summary.taxes.base", { taxName })}:</dt>
              <dd>{formatCurrency(productBase, i18n.language, currency)}</dd>
              <dt>{`${taxName} (19%)`}:</dt>
              <dd>{formatCurrency(tax, i18n.language, currency)}</dd>
              <dt>{t("details.summary.taxes.sum")}:</dt>
              <dd>{formatCurrency(price, i18n.language, currency)}</dd>
            </dl>
          </li>
        );
      })}
      <li>
        <dl className={styles.taxListTotal}>
          <dt>{t("common.total")}</dt>
          <dd>{total}</dd>
        </dl>
      </li>
    </ul>
  );
};

export default TaxList;
