// extracted by mini-css-extract-plugin
export var petInfoForm = "PetInfoForm-module--petInfoForm--2Y-S6";
export var icon = "PetInfoForm-module--icon--0qbTu";
export var title = "PetInfoForm-module--title--K5+4r";
export var save = "PetInfoForm-module--save--cblkP";
export var cancel = "PetInfoForm-module--cancel--v-s6N";
export var petName = "PetInfoForm-module--petName--MzwC5";
export var breedSelect = "PetInfoForm-module--breedSelect--dwPx9";
export var sexSelect = "PetInfoForm-module--sexSelect--AICBP";
export var birthdateWrapper = "PetInfoForm-module--birthdateWrapper--ryfj9";
export var birthDateErrorMessage = "PetInfoForm-module--birthDateErrorMessage--PhlAn";
export var birthDateWarningMessage = "PetInfoForm-module--birthDateWarningMessage--Ae1OB";