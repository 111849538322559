import {
  Resetter,
  SetterOrUpdater,
  useRecoilValue,
  useResetRecoilState,
  useSetRecoilState,
} from "recoil";

import { DetailsSectionName } from "../../../pages/results/[resultUuid]/details";
import { sectionBeingEditedState } from "../state/atom";

export function useSectionBeingEdited(): DetailsSectionName | null {
  return useRecoilValue(sectionBeingEditedState);
}

export function useSetSectionBeingEdited(): (section: DetailsSectionName) => void {
  const setter: SetterOrUpdater<DetailsSectionName> = useSetRecoilState(sectionBeingEditedState);

  return (section: DetailsSectionName): void => {
    setter(section);
  };
}

export function useResetSectionBeingEdited(): () => void {
  const resetState: Resetter = useResetRecoilState(sectionBeingEditedState);

  return (): void => {
    resetState();
  };
}
