import { useTranslation } from "gatsby-plugin-react-i18next";
import { ReactElement } from "react";

import Expandable from "../../atoms/expandable/Expandable";
import { Product } from "../../features/quotes-funnel/domain/product";
import { ProductId } from "../../settings/products";
import { formatCurrency } from "../../utils/currency";
import TaxList from "../tax-list/TaxList";
import * as styles from "./ShoppingCartExpandable.module.scss";

interface ShoppingCartExpandableProps {
  policyStartDate: string;
  products: Product[];
  total: number;
  shouldListTaxes: boolean;
  currency?: string;
  adoptionClassName?: string;
}

const ShoppingCartExpandable = ({
  policyStartDate,
  products,
  total,
  shouldListTaxes,
  currency = "EUR",
  adoptionClassName,
}: ShoppingCartExpandableProps): ReactElement => {
  const { t, i18n } = useTranslation();
  const healthProduct = products.find(
    (product) => product.id === ProductId.healthBasic || product.id === ProductId.healthPlatinum
  );
  const preventionProduct = products.find((product) => product.id === ProductId.prevention);
  const glProduct = products.find((product) => product.id === ProductId.glExtended);
  const productPrices = {
    healthPrice: healthProduct && formatCurrency(healthProduct.price, i18n.language, currency),
    preventionPrice:
      preventionProduct && formatCurrency(preventionProduct.price, i18n.language, currency),
    glPrice: glProduct ? formatCurrency(glProduct.price, i18n.language, currency) : undefined,
  };
  const productKeySuffix = glProduct ? "with_gl" : "without_gl";

  return (
    <div className={adoptionClassName}>
      <Expandable
        id={"shopping-cart-expandable"}
        expandText={t("common.see_more")}
        collapseText={t("common.see_less")}
        adoptionClassName={styles.shoppingCartExpandable}
      >
        <ul>
          <li>
            {t(`details.summary.expandable.breakdown_price_${productKeySuffix}`, productPrices)}
          </li>{" "}
          <li>{t("details.summary.expandable.policy_start_date", { policyStartDate })}</li>
          {shouldListTaxes && (
            <li>
              <TaxList total={formatCurrency(total, i18n.language, currency)} products={products} />
            </li>
          )}
        </ul>
      </Expandable>
    </div>
  );
};

export default ShoppingCartExpandable;
