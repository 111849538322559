import { navigate } from "gatsby";
import { Dispatch, SetStateAction, useCallback } from "react";

import { CountryCode } from "../../../../settings/countries";
import { PageId } from "../../../../settings/pages";
import { ComplianceError } from "../../../../utils/error-utils/errors";
import { shouldShowComplianceErrorMessage } from "../../../../utils/locale-configuration-utils";
import { useCustomCheckoutSession } from "../../application/result-use-cases";
import { handleCustomCheckoutSessionErrors } from "../error-utils/catch-error-handlers";

type HandleCustomCheckoutHook = (
  uuid: string,
  country: CountryCode,
  setHasComplianceErrorMessage: Dispatch<SetStateAction<boolean>>,
  pageContext: PageId
) => Promise<void>;

export const useHandleCustomCheckoutSession = (): HandleCustomCheckoutHook => {
  const customCheckoutSession = useCustomCheckoutSession();

  return useCallback(
    async (
      uuid: string,
      country: CountryCode,
      setHasComplianceErrorMessage: Dispatch<SetStateAction<boolean>>,
      pageContext
    ): Promise<void> => {
      try {
        await customCheckoutSession();
        void navigate(`/results/${uuid}/checkout/`);
      } catch (err) {
        handleCustomCheckoutSessionErrors(err as Error, pageContext);

        if (err instanceof ComplianceError && shouldShowComplianceErrorMessage(country)) {
          setHasComplianceErrorMessage(true);
        }
      }
    },
    [customCheckoutSession]
  );
};
