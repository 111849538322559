import cn from "classnames";
import { useI18next } from "gatsby-plugin-react-i18next";
import { useEffect, useState } from "react";
import { useSearchBox } from "react-instantsearch";

import TextFormControl from "../../atoms/text-form-control/TextFormControl";
import { getWindow } from "../../utils/browser-features";
import * as styles from "./BreedSelect.module.scss";

interface BreedSearchBoxProps {
  breedName: string | null | undefined;
  toggleBreedHits: () => void;
}

const BreedSearchBox = ({ breedName, toggleBreedHits }: BreedSearchBoxProps): JSX.Element => {
  const { t } = useI18next();
  const [query, setQuery] = useState("");
  const [inputIsFocused, setInputIsFocused] = useState(false);

  const { refine } = useSearchBox();

  useEffect(() => {
    if (breedName) {
      setQuery(breedName);
    }
  }, [breedName, refine]);

  const handleChange = (event): void => {
    const value = event.target.value;

    setQuery(value);
    refine(value);
  };

  const handleClick = (): void => {
    setQuery("");
    toggleBreedHits();
  };

  const handleBlur = (): void => {
    setInputIsFocused(false);
    setTimeout((): void => {
      if (getWindow()?.sessionStorage?.getItem("breedName")) {
        setQuery(getWindow()?.sessionStorage.getItem("breedName") as string);
      }
      toggleBreedHits();
    }, 300);
  };

  return (
    <section className={cn(styles.breedSearchBoxWrapper)}>
      <TextFormControl
        name="search_breed"
        label={t("onboarding.breed.field.title")}
        value={query}
        onChange={handleChange}
        onFocus={() => setInputIsFocused(true)}
        onClick={handleClick}
        onBlur={handleBlur}
      />
      <svg
        className={cn("icon icon--search", { [styles.inputIsFocused]: inputIsFocused })}
        aria-hidden="true"
      >
        <use href="#icon-search" />
      </svg>
    </section>
  );
};

export default BreedSearchBox;
