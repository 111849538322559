import cn from "classnames";
import { useTranslation } from "gatsby-plugin-react-i18next";
import { ReactElement, useMemo } from "react";

import { formatCurrency } from "../../../../../utils/currency";
import { usePromotionCode } from "../../../application/promotion-code-use-cases";
import { useShoppingCart } from "../../../application/shopping-cart-use-cases";
import DiscountBadge, { DiscountBadgeVariants } from "../../atoms/discount-badge/DiscountBadge";
import * as styles from "../promotion-code-card/PromotionCodeCard.module.scss";

interface PromotionCodeCardProps {
  hasBecasDiscount?: boolean;
}

const PromotionCodeCard = ({ hasBecasDiscount }: PromotionCodeCardProps): ReactElement => {
  const { t, i18n } = useTranslation();
  const cart = useShoppingCart();
  const promotionCode = usePromotionCode();

  const monthlyTotalPriceFormatted =
    cart &&
    promotionCode &&
    formatCurrency(cart.selectedProductsMonthlyTotalPrice, i18n.language, cart.currency);

  const showPriceBreakdownMessage = useMemo(() => {
    if (!promotionCode || !cart) {
      return null;
    }

    const monthlyDiscountedPriceBreakdown = cart.getMonthlyDiscountedPriceBreakdown();

    return (
      <>
        {monthlyDiscountedPriceBreakdown.map((item, index) => {
          const formattedPayment = formatCurrency(item.payment, i18n.language, cart.currency);

          return (
            <p className={cn({ [styles.firstPayment]: index === 0 })} key={index}>
              {index === 0 ? (
                <>
                  {t(`pg_details.promotion_code_card.price_breakdown.${index + 1}_month`, {
                    paymentAmount: formattedPayment,
                  })}
                  <span>
                    ({monthlyTotalPriceFormatted}
                    {t("common.slash_monthly")}{" "}
                    {t(
                      `pg_details.promotion_code_card.price_after_${monthlyDiscountedPriceBreakdown.length}_month`
                    )}
                    )
                  </span>
                </>
              ) : (
                (index === 1 || index === 2) &&
                t(`pg_details.promotion_code_card.price_breakdown.${index + 1}_month`, {
                  paymentAmount: formattedPayment,
                })
              )}
            </p>
          );
        })}
        <p>
          {t(`pg_details.promotion_code_card.price_breakdown.following_months`, {
            paymentAmount: monthlyTotalPriceFormatted,
          })}
        </p>
      </>
    );
  }, [cart, i18n.language, monthlyTotalPriceFormatted, promotionCode, t]);

  return (
    <>
      {promotionCode && (
        <div className={styles.promotionCard}>
          {hasBecasDiscount ? (
            <>
              <p className={styles.discountPrice}>
                {formatCurrency(
                  cart.selectedProductsMonthlyDiscountedPrice,
                  i18n.language,
                  cart.currency
                )}
                {t("common.slash_monthly")}
              </p>
              <p>{t("details.summary.becas_discount.message")}</p>
            </>
          ) : (
            promotionCode && promotionCode.isAmountCoupon && showPriceBreakdownMessage
          )}
          <DiscountBadge
            discountName={
              hasBecasDiscount
                ? promotionCode.code
                : t("pg_details.promotion_code_card.applied_code", {
                    appliedCode: promotionCode.code,
                  })
            }
            variantName={DiscountBadgeVariants.alternate}
            adoptionClassName={styles.discountBadge}
          />
        </div>
      )}
    </>
  );
};

export default PromotionCodeCard;
