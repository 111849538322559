import { useMemo } from "react";
import { useTranslation } from "react-i18next";

import { useShoppingCart } from "../../features/quotes-funnel/application/shopping-cart-use-cases";
import { useResult } from "../../organisms/result/use-cases/result-use-cases";
import { ProductId } from "../../settings/products";
import { getDraftContractLink } from "../draft-contract";

type DisclaimerLink = Record<string, string>;

const useDisclaimerDocumentLinks = (): DisclaimerLink[] => {
  const { t } = useTranslation();
  const cart = useShoppingCart();
  const result = useResult();

  const healthProduct = cart.getSelectedHealthProduct();
  const glProduct = cart.getProduct(ProductId.glExtended)?.selected;

  return useMemo(() => {
    const addInfoDisclaimer = (
      productKey: string,
      draftContractLink?: string | null | undefined
    ): DisclaimerLink => {
      const link: string = draftContractLink ?? t(`document.${productKey}.link`);
      const text = t(`document.${productKey}.text`);

      return { link, text };
    };

    const links: DisclaimerLink[] = [];

    if (healthProduct) {
      const healthQuote = result?.getQuote(healthProduct.id);
      const healthDraftContractLink = getDraftContractLink(healthQuote?.uuid);

      links.push(addInfoDisclaimer("health.product"));
      links.push(addInfoDisclaimer("health.conditions", healthDraftContractLink));
    }

    if (glProduct) {
      const glQuote = result?.getQuote(ProductId.glExtended);
      const glDraftContractLink = getDraftContractLink(glQuote?.uuid);

      links.push(addInfoDisclaimer("gl.product"));
      links.push(addInfoDisclaimer("gl.conditions", glDraftContractLink));
    }

    return links;
  }, [healthProduct, glProduct, result, t]);
};

export default useDisclaimerDocumentLinks;
