import { useTranslation } from "gatsby-plugin-react-i18next";

const Disclaimer = ({ documentLinks }) => {
  const { t } = useTranslation();
  const documents = documentLinks
    .map((documentLink) => `<a href="${documentLink.link}">${documentLink.text}</a>`)
    .join(", ");

  const note = `<a href=${t("document.note.link")}>${t("document.note.text")}</a>`;

  return (
    <span
      className="disclaimer"
      dangerouslySetInnerHTML={{ __html: t("disclaimer.info", { documents, note }) }}
    ></span>
  );
};

export default Disclaimer;
