import cn from "classnames";
import { ReactElement, ReactNode, useState } from "react";

import { useDealEventProperties } from "../../organisms/deal-event-properties/use-cases/deal-event-properties-use-cases";
import { Events, track } from "../../utils/analytics";
import * as styles from "./Expandable.module.scss";

interface ExpandableProps {
  id: string;
  expandText: string;
  collapseText: string;
  children: ReactNode;
  initiallyExpanded?: boolean;
  adoptionClassName?: string;
}

const Expandable = ({
  id,
  expandText,
  collapseText,
  children,
  initiallyExpanded = false,
  adoptionClassName,
}: ExpandableProps): ReactElement => {
  const [isExpanded, setExpanded] = useState<boolean>(initiallyExpanded);
  const dealEventProperties = useDealEventProperties();

  const toggle = (): void => {
    const properties = { block: id, ...dealEventProperties };
    const eventName = !isExpanded ? Events.BLOCK_EXPANDED : Events.BLOCK_COLLAPSED;

    setExpanded(!isExpanded);

    track(eventName, properties);
  };

  return (
    <div className={cn(styles.expandable, adoptionClassName, "expandable")}>
      <button
        type={"button"}
        className={cn(styles.readMore, "read-more")}
        onClick={toggle}
        aria-controls={id}
        aria-expanded={isExpanded}
      >
        {isExpanded ? collapseText : expandText}
      </button>
      <div
        id={id}
        className={cn(styles.expandableContent, "expandable-content")}
        hidden={!isExpanded}
      >
        {children}
      </div>
    </div>
  );
};

export default Expandable;
