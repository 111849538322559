import cn from "classnames";
import { Link } from "gatsby";
import { useTranslation } from "gatsby-plugin-react-i18next";
import { Fragment, ReactNode } from "react";

import Button from "../../atoms/button/Button";
import { Events, track } from "../../utils/analytics";
import useVisibilityTracker from "../../utils/hooks/useVisibilityTracker";
import * as styles from "./InfoCard.module.scss";

interface InfoCardElement {
  title: string;
  value: string;
  variantName?: string;
  errorMessage?: ReactNode;
}

interface InfoCardEditOptions {
  editPath?: string;
  onEdit?: (sectionName) => void;
  forceDisabled?: boolean;
}

interface InfoCardProps {
  sectionName?: string;
  iconName: string;
  title: string;
  variantName?: string;
  adoptionClassName?: string;
  list?: InfoCardElement[];
  editOptions?: InfoCardEditOptions;
  footnote?: string;
}
const InfoCard = ({
  sectionName,
  iconName,
  title,
  variantName,
  adoptionClassName,
  list,
  editOptions,
  footnote,
}: InfoCardProps): JSX.Element => {
  const { t } = useTranslation();
  const { ref } = useVisibilityTracker(
    "details",
    {
      amount: 0.5,
    },
    {
      section: sectionName,
    }
  );

  const handleClick = () => {
    track(Events.TAPPED_ON_EDIT, {
      section: sectionName,
    });

    editOptions && editOptions.onEdit && editOptions.onEdit(sectionName);
  };

  return (
    <section
      className={cn(styles.infoCard, adoptionClassName, {
        [styles[variantName as string]]: variantName,
      })}
      ref={ref}
    >
      <svg className={cn(styles.icon, "icon", `icon--${iconName}`)} aria-hidden="true">
        <use href={`#icon-${iconName}`}></use>
      </svg>
      <h2 className={styles.title}>{title}</h2>

      {list && (
        <dl>
          {list.map(({ variantName, title, value, errorMessage }, i) => {
            return (
              <Fragment key={i}>
                <div className={cn(styles.item, { [styles[variantName as string]]: variantName })}>
                  <dt>{title}</dt>
                  <dd>{value}</dd>
                </div>
                {errorMessage && <span className={styles.errorMessage}>{errorMessage}</span>}
              </Fragment>
            );
          })}
        </dl>
      )}

      {footnote && <p className={styles.footnote}>{footnote}</p>}
      {editOptions?.onEdit && (
        <Button
          type={"button"}
          className={styles.editButton}
          onClick={handleClick}
          disabled={editOptions.forceDisabled}
        >
          {t("common.edit")}
        </Button>
      )}
      {editOptions?.editPath && (
        <Link
          to={editOptions?.editPath}
          className={cn(styles.editLink, editOptions.forceDisabled && styles.editLinkDisabled)}
          onClick={() => track(Events.TAPPED_ON_EDIT_LINK, { section: sectionName })}
        >
          {t("common.edit")}
        </Link>
      )}
    </section>
  );
};

export default InfoCard;
