import { Dispatch, SetStateAction, useCallback } from "react";

import { useConfirmResult } from "../../../../organisms/result/use-cases/result-use-cases";
import { CountryCode } from "../../../../settings/countries";
import { PageId } from "../../../../settings/pages";
import { experiments, isExperimentDefaultVariant } from "../../../../utils/experiments";
import { shouldAllowCustomCheckoutSession } from "../../../../utils/locale-configuration-utils";
import { rollbar } from "../../../../utils/rollbar";
import { useHandleCustomCheckoutSession } from "./useHandleCustomCheckoutSession";
import { useHandleStripeCheckoutSession } from "./useHandleStripeCheckoutSession";

type ConfirmAndGoToCheckoutHook = (
  uuid: string,
  country: CountryCode,
  setIsSubmitting: Dispatch<SetStateAction<boolean>>,
  setHasSubmitError: Dispatch<SetStateAction<boolean>>,
  setHasComplianceErrorMessage: Dispatch<SetStateAction<boolean>>,
  setHasPromotionCodeErrorMessage: Dispatch<SetStateAction<boolean>>,
  pageContext: PageId
) => Promise<void>;

export const useConfirmAndGoToCheckout = (): ConfirmAndGoToCheckoutHook => {
  const confirmResult = useConfirmResult();
  const handleStripeCheckoutSession = useHandleStripeCheckoutSession();
  const handleCustomCheckoutSession = useHandleCustomCheckoutSession();

  return useCallback(
    async (
      uuid,
      country,
      setIsSubmitting,
      setHasSubmitError,
      setHasComplianceErrorMessage,
      setHasPromotionCodeErrorMessage,
      pageContext
    ): Promise<void> => {
      const isAllowedCustomCheckoutSession =
        shouldAllowCustomCheckoutSession() &&
        isExperimentDefaultVariant(experiments.allowCustomCheckoutSession);

      try {
        await confirmResult();

        if (isAllowedCustomCheckoutSession) {
          await handleCustomCheckoutSession(
            uuid,
            country,
            setHasComplianceErrorMessage,
            pageContext
          );
        } else {
          await handleStripeCheckoutSession(
            country,
            setHasComplianceErrorMessage,
            setHasPromotionCodeErrorMessage,
            pageContext
          );
        }
      } catch (err) {
        const e = err as Error;

        rollbar.error(e.message, e, { context: `confirmResult/${pageContext}` });
        setIsSubmitting(false);
        setHasSubmitError(true);
      }
    },
    [confirmResult, handleStripeCheckoutSession, handleCustomCheckoutSession]
  );
};
