import { CalendarDate, getLocalTimeZone, today } from "@internationalized/date";

import { minPetAgeNumberOfMonths } from "../../settings/pet";

export const calcPolicyStartDate = (petBirthDate: CalendarDate): CalendarDate => {
  const petBirthDatePlusTwoMonths = new CalendarDate(
    petBirthDate.year,
    petBirthDate.month,
    petBirthDate.day
  ).add({
    months: minPetAgeNumberOfMonths,
  });
  const localDate = today(getLocalTimeZone());

  if (petBirthDate.compare(localDate.subtract({ months: minPetAgeNumberOfMonths })) <= 0) {
    return localDate.add({ days: 1 });
  }

  return petBirthDatePlusTwoMonths;
};
