// extracted by mini-css-extract-plugin
export var infoCard = "InfoCard-module--infoCard--itWk1";
export var icon = "InfoCard-module--icon--7JYSu";
export var title = "InfoCard-module--title--ZgERd";
export var editButton = "InfoCard-module--editButton--Q4div";
export var editLink = "InfoCard-module--editLink--IgPSv";
export var editLinkDisabled = "InfoCard-module--editLinkDisabled---4xEp";
export var item = "InfoCard-module--item--2lQmD";
export var errorMessage = "InfoCard-module--errorMessage--uIanA";
export var footnote = "InfoCard-module--footnote--h-gQJ";
export var petParent = "InfoCard-module--pet-parent--t-pVj";
export var phoneCountryCode = "InfoCard-module--phone-country-code--QcECJ";
export var phoneNumber = "InfoCard-module--phone-number--eI47Z";