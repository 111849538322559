import { CalendarDate, getLocalTimeZone, today } from "@internationalized/date";
import { useTranslation } from "gatsby-plugin-react-i18next";
import { useState } from "react";
import * as Yup from "yup";

import { maxPetAgeNumberOfYears, minPetAgeNumberOfMonths } from "../../../settings/pet";
import { FormikConfig } from "../../utils/formik-types";

interface UsePetBirthDateParams {
  initialBirthDate?: CalendarDate;
  petName?: string;
}

interface UsePetBirthDateFormValues {
  pet_birth_date?: CalendarDate;
}

interface UsePetBirthDate extends FormikConfig<UsePetBirthDateFormValues> {
  tooYoungWarning?: string;
}

const usePetBirthDate = ({ initialBirthDate, petName }: UsePetBirthDateParams): UsePetBirthDate => {
  const { t } = useTranslation();
  const [tooYoungWarning, setTooYoungWarning] = useState<string | undefined>(undefined);

  const localDate = today(getLocalTimeZone());
  const minPetBirthDate = new CalendarDate(localDate.year, localDate.month, localDate.day).subtract(
    {
      months: minPetAgeNumberOfMonths,
    }
  );
  const maxPetBirthDate = new CalendarDate(localDate.year, localDate.month, localDate.day).subtract(
    {
      years: maxPetAgeNumberOfYears,
    }
  );

  const initialValues: UsePetBirthDateFormValues = {
    pet_birth_date: initialBirthDate || undefined,
  };

  const validationSchema = Yup.object({
    pet_birth_date: Yup.mixed().test({
      name: "validate-birth-date",
      test(value: CalendarDate, ctx) {
        setTooYoungWarning(undefined);

        if (!value) {
          return ctx.createError({ message: t("common.validation.required") });
        }

        if (value.compare(minPetBirthDate) > 0 && !(value.compare(localDate) >= 0)) {
          setTooYoungWarning(t("common.validation.pet_too_young", { petName }));
        }

        if (value.compare(maxPetBirthDate) <= 0) {
          return ctx.createError({
            message: t("common.validation.pet_too_old"),
          });
        }

        if (value.compare(localDate) >= 0) {
          return ctx.createError({
            message: t("common.validation.valid_birth_date"),
          });
        }

        return true;
      },
    }),
  });

  return {
    initialValues,
    validationSchema,
    tooYoungWarning,
  };
};

export default usePetBirthDate;
