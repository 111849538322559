import { fetchApi } from "../../../utils/request";

export const notifyPreContract = async (resultUuid: string): Promise<Record<string, unknown>> => {
  return await fetchApi(
    `${process.env.GATSBY_API_BASE}/results/${resultUuid}/draft_contracts/email`,
    {
      method: "POST",
    }
  );
};
