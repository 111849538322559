import { CalendarDate, parseDateTime, toCalendarDate } from "@internationalized/date";
import cn from "classnames";
import { Form, Formik, FormikHelpers, FormikProps } from "formik";
import { useTranslation } from "gatsby-plugin-react-i18next";
import parse from "html-react-parser";
import { forwardRef, ReactElement, ReactNode, Ref } from "react";
import * as Yup from "yup";

import Button from "../../../atoms/button/Button";
import Heading from "../../../atoms/heading/Heading";
import Select from "../../../atoms/select/Select";
import TextFormControl from "../../../atoms/text-form-control/TextFormControl";
import usePetBirthDate from "../../../core/domain/hooks/usePetBirthDate";
import { calcPolicyStartDate } from "../../../core/utils/policy";
import DateFieldFormControl from "../../../features/quotes-funnel/ui/molecules/date-field-form-control/DateFieldFormControl";
import BreedSelect from "../../../molecules/breed-select/BreedSelect";
import { PetSpecies } from "../../../settings/pet";
import { Events, track } from "../../../utils/analytics";
import { handleUpdateResultErrors } from "../../../utils/error-utils/catch-error-handlers";
import { useResult, useUpdateResult } from "../../result/use-cases/result-use-cases";
import * as styles from "./PetInfoForm.module.scss";

interface PetInfoFormProps {
  sectionName: string;
  adoptionClassName?: string;
  onEditCancelled: () => void;
  onEditSubmitted: (values) => void;
}

export interface PetInfoFormValues {
  pet_name: string;
  pet_birth_date: CalendarDate | undefined;
  pet_breed_kb_key: string;
  pet_sex: string;
}

interface SexOption {
  value: "male" | "female";
  label: string;
}

const petSpeciesIcon: { [key in keyof typeof PetSpecies]: string } = {
  cat: "cat1",
  dog: "dog1",
};

const PetInfoFormComponent = (
  { sectionName, adoptionClassName, onEditCancelled, onEditSubmitted }: PetInfoFormProps,
  ref: Ref<HTMLFormElement>
): ReactElement => {
  const result = useResult();
  const { t } = useTranslation();
  const updateResult = useUpdateResult();
  const {
    initialValues: petBirthDateInitialValues,
    validationSchema: petBirthDateValidationSchema,
    tooYoungWarning,
  } = usePetBirthDate({
    initialBirthDate: result ? toCalendarDate(parseDateTime(result.pet_birth_date)) : undefined,
    petName: result?.pet_name,
  });

  const sexOptions: SexOption[] = [
    { value: "male", label: t("common.pet_sex.male") },
    { value: "female", label: t("common.pet_sex.female") },
  ];

  const handleCancel = (): void => {
    track(Events.TAPPED_ON_CANCEL_ACTION, { action: "Cancel edit info", section: sectionName });
    onEditCancelled();
  };

  const renderSexLabel = (selectedSex: string): ReactNode => {
    const option = sexOptions.find((option) => option.value === selectedSex);

    return option ? (
      <i className={"value-wrapper"}>
        <span className="listbox-label">{option.label}</span>
      </i>
    ) : null;
  };

  return (
    <>
      {result && (
        <Formik
          initialValues={{
            pet_name: result.pet_name,
            pet_birth_date: petBirthDateInitialValues.pet_birth_date,
            pet_breed_kb_key: result.pet_breed.kb_key,
            pet_sex: result.pet_sex,
          }}
          validationSchema={Yup.object({
            pet_name: Yup.string().required(t("common.validation.required")),
            pet_breed_kb_key: Yup.string().required(t("common.validation.required")),
            pet_sex: Yup.string().required(t("common.validation.required")),
          }).concat(petBirthDateValidationSchema)}
          onSubmit={async (
            values: PetInfoFormValues,
            { setSubmitting }: FormikHelpers<PetInfoFormValues>
          ) => {
            setSubmitting(true);
            track(Events.TAPPED_ON_SAVE_ACTION, { action: "Save edit info", section: sectionName });

            if (!updateResult) {
              return;
            }

            const updateData = {
              ...values,
              ...(values.pet_birth_date && {
                pet_birth_date: values.pet_birth_date.toString(),
                policy_start_date: calcPolicyStartDate(values.pet_birth_date).toString(),
              }),
            };

            try {
              await updateResult(updateData);
              onEditSubmitted(values);
            } catch (err) {
              handleUpdateResultErrors(err, "PetInfoForm");
              alert(t("landing.error_message"));
              setSubmitting(false);
            }
          }}
        >
          {({
            isSubmitting,
            isValid,
            setFieldValue,
            dirty,
            touched,
            errors,
            setFieldTouched,
            values,
            initialValues,
          }: FormikProps<PetInfoFormValues>) => (
            <Form className={cn(styles.petInfoForm, adoptionClassName)} ref={ref}>
              <svg
                className={cn(styles.icon, `icon--${petSpeciesIcon[result.pet_species]}`)}
                aria-hidden="true"
              >
                <use href={`#icon-${petSpeciesIcon[result.pet_species]}`}></use>
              </svg>
              <Heading level={2} adoptionClassName={cn(styles.title)}>
                {t("details.pet_data.title", { petName: result.pet_name })}
              </Heading>
              <Button
                adoptionClassName={styles.save}
                type={"submit"}
                disabled={isSubmitting || !isValid || !dirty}
                isLoading={isSubmitting}
              >
                {t("common.save")}
              </Button>
              <Button
                adoptionClassName={styles.cancel}
                type={"button"}
                onClick={handleCancel}
                disabled={isSubmitting}
              >
                {t("common.cancel")}
              </Button>
              <TextFormControl
                label={t("details.pet_data.name.field.title")}
                name={"pet_name"}
                adoptionClassName={styles.petName}
              />
              <div className={styles.birthdateWrapper}>
                <DateFieldFormControl
                  name="pet_birth_date"
                  defaultValue={initialValues.pet_birth_date}
                  onChange={(e: CalendarDate) => {
                    if (e?.year >= 1000) {
                      setFieldTouched("pet_birth_date", true);
                      setFieldValue("pet_birth_date", e);
                    }
                  }}
                  label={t("common.birthdate")}
                />
                {touched.pet_birth_date && errors.pet_birth_date && (
                  <p className={styles.birthDateErrorMessage} role="alert" tabIndex={-1}>
                    {String(errors.pet_birth_date)}
                  </p>
                )}
                {tooYoungWarning && (
                  <p className={styles.birthDateWarningMessage}>{parse(tooYoungWarning)}</p>
                )}
              </div>
              <BreedSelect
                adoptionClassName={cn(styles.breedSelect)}
                species={result.pet_species}
                selectBreedUuid={(e: string) => setFieldValue("pet_breed_kb_key", e)}
                preselectedBreedName={result.pet_breed.name}
              />
              <Select
                name="pet_sex"
                buttonLabel={renderSexLabel(values.pet_sex)}
                handleChange={(e: string) => setFieldValue("pet_sex", e)}
                adoptionClassName={styles.sexSelect}
                options={sexOptions}
              />
            </Form>
          )}
        </Formik>
      )}
    </>
  );
};

export default forwardRef(PetInfoFormComponent);
