import { useCallback } from "react";
import { SetterOrUpdater, useRecoilState } from "recoil";

import { handleNotifyPreContractErrors } from "../../../utils/error-utils/catch-error-handlers";
import { notifyPreContract } from "../data/draft-contract-repository";
import { preContractNotifiedState } from "../state/draft-contract-state";

export const usePreContractNotified = (): [boolean, SetterOrUpdater<boolean>] => {
  const [preContractNotified, setPreContractNotified] = useRecoilState(preContractNotifiedState);

  return [preContractNotified, setPreContractNotified];
};

export const useNotifyPreContract = (): ((resultUuid: string) => Promise<void>) => {
  return useCallback(async (resultUuid: string) => {
    try {
      await notifyPreContract(resultUuid);
    } catch (err) {
      handleNotifyPreContractErrors(err as Error, "notifyPreContract");
    }
  }, []);
};
