import classNames from "classnames";
import { useTranslation } from "gatsby-plugin-react-i18next";
import parse from "html-react-parser";
import { ReactNode, useEffect, useState } from "react";

import Button from "../../../../../atoms/button/Button";
import Checkbox from "../../../../../atoms/checkbox/Checkbox";
import Disclaimer from "../../../../../atoms/disclaimer/Disclaimer";
import Heading from "../../../../../atoms/heading/Heading";
import ShoppingCartExpandable from "../../../../../molecules/shopping-cart-expandable/ShoppingCartExpandable";
import {
  useResult,
  useUpdateCommercialAcceptedState,
  useUpdatePaymentIntervalState,
} from "../../../../../organisms/result/use-cases/result-use-cases";
import { DiscountCampaigns } from "../../../../../settings/discounts";
import { Events, track, trackChoice } from "../../../../../utils/analytics";
import { formatCurrency } from "../../../../../utils/currency";
import { formatDate } from "../../../../../utils/date";
import { getDraftContractLink } from "../../../../../utils/draft-contract";
import useDisclaimerDocumentLinks from "../../../../../utils/hooks/useDisclaimerDocumentLinks";
import {
  shouldAllowFakeChipNumber,
  shouldListTaxes,
  shouldShowAlternativeDisclaimer,
} from "../../../../../utils/locale-configuration-utils";
import { usePromotionCode } from "../../../application/promotion-code-use-cases";
import { useShoppingCart } from "../../../application/shopping-cart-use-cases";
import { Product } from "../../../domain/product";
import { PaymentIntervalMonths } from "../../../settings/payments";
import PromotionCodeCard from "../promotion-code-card/PromotionCodeCard";
import * as styles from "./ShoppingCart.module.scss";

interface ShoppingCartProps {
  isSubmitting?: boolean;
  handleSubmit?: () => void;
  forceDisabledSubmit?: boolean;
  hasSubmitError?: boolean;
  errorMessage?: ReactNode;
  petInfoFormRef: React.RefObject<HTMLFormElement>;
  setHasEditPetInfoWarning: (hasEditPetInfoWarning: boolean) => void;
}

const ShoppingCart = ({
  isSubmitting = false,
  handleSubmit,
  forceDisabledSubmit = false,
  hasSubmitError = false,
  errorMessage,
  petInfoFormRef,
  setHasEditPetInfoWarning,
}: ShoppingCartProps): JSX.Element => {
  const { t, i18n } = useTranslation();
  const result = useResult();
  const cart = useShoppingCart();
  const healthProduct = cart.getSelectedHealthProduct() as Product;
  const disclaimerDocumentLinks = useDisclaimerDocumentLinks();
  const updateCommercialAcceptedState = useUpdateCommercialAcceptedState();
  const updatePaymentIntervalState = useUpdatePaymentIntervalState();
  const promotionCode = usePromotionCode();
  const hasBecasDiscount = promotionCode && promotionCode.campaignName in DiscountCampaigns;
  const [isFirstRender, setIsFirstRender] = useState(true);
  const [isChipRequestAccepted, setIsChipRequestAccepted] = useState(false);
  const [isGeneralTermsAccepted, setIsGeneralTermsAccepted] = useState(false);
  const [isCommercialAccepted, setIsCommercialAccepted] = useState(false);
  const partialPaymentInterval = result?.payment_interval_months;

  const monthlyFormattedPrice: string = t(
    "details.summary.payment_interval_months.monthly_amount",
    {
      amount: formatCurrency(
        cart.selectedProductsMonthlyTotalPrice,
        i18n.language,
        result?.currency
      ),
    }
  );
  const yearlyFormattedPrice: string = t("details.summary.payment_interval_months.yearly_amount", {
    amount: formatCurrency(cart.selectedProductsTotalPrice, i18n.language, result?.currency),
  });

  const hasFakeChipNumber = result?.country && shouldAllowFakeChipNumber(result.country);

  const handleChipRequestAccepted = (e) => {
    const value = !isChipRequestAccepted;
    const properties = {
      question: "Chip Request Accepted",
    };

    trackChoice(e, properties);
    setIsChipRequestAccepted(value);
  };

  const handleChangeGeneralTerms = (e) => {
    const properties = {
      question: "General Terms Accepted",
    };

    trackChoice(e, properties);
    setIsFirstRender(false);
    setIsGeneralTermsAccepted(!isGeneralTermsAccepted);
  };

  const handleChangeCommercialAccepted = (e) => {
    const value = !isCommercialAccepted;
    const properties = {
      question: "Commercial Accepted",
    };

    trackChoice(e, properties);
    setIsCommercialAccepted(value);
  };

  const handleClick = () => {
    const properties = {
      buttonDisabled: isDisabledSubmit,
    };

    track(Events.CLICKED_GO_TO_PAYMENT, properties);

    if (handleSubmit && !isDisabledSubmit) {
      handleSubmit();
    }
  };

  const handleEditPetInfoWarning = () => {
    if (petInfoFormRef.current) {
      petInfoFormRef.current.scrollIntoView({ behavior: "smooth", block: "center" });
      setHasEditPetInfoWarning(true);
    }
  };

  const errorNoGeneralTermsSelected =
    !isFirstRender && !isGeneralTermsAccepted ? t("landing.error_input") : undefined;
  const errorNoChipRequestSelected =
    !isFirstRender && !isChipRequestAccepted ? t("landing.error_input") : undefined;

  const isDisabledSubmit =
    !isGeneralTermsAccepted ||
    (hasFakeChipNumber && !isChipRequestAccepted) ||
    isSubmitting ||
    cart.selectedProductsTotalPrice === 0 ||
    forceDisabledSubmit;

  useEffect(() => {
    updateCommercialAcceptedState(isCommercialAccepted);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCommercialAccepted]);

  useEffect(() => {
    if (partialPaymentInterval) {
      updatePaymentIntervalState(partialPaymentInterval);
    }
  }, [partialPaymentInterval, updatePaymentIntervalState]);

  return (
    <>
      {result && (
        <div className={classNames("or-shopping-cart", styles.shoppingCart)}>
          <section className={styles.card}>
            {result.isPartialPaymentAvailable ? (
              <>
                <Heading level={1} adoptionClassName={styles.priceSelected}>
                  <span className={styles.cardTitle}>
                    {t("details.summary.title.barkibu_insurance")}
                  </span>
                  {partialPaymentInterval === PaymentIntervalMonths.monthly
                    ? monthlyFormattedPrice
                    : yearlyFormattedPrice}
                </Heading>
                {promotionCode && partialPaymentInterval === PaymentIntervalMonths.monthly && (
                  <PromotionCodeCard {...(hasBecasDiscount && { hasBecasDiscount: true })} />
                )}
                <ShoppingCartExpandable
                  policyStartDate={formatDate(result.policy_start_date, i18n.language)}
                  products={cart.selectedProducts}
                  total={cart.selectedProductsTotalPrice}
                  shouldListTaxes={shouldListTaxes(result.country)}
                  adoptionClassName={styles.shoppingCartExpandable}
                />
              </>
            ) : (
              <Heading level={1} adoptionClassName={styles.cardTitle}>
                {t("details.summary.title.barkibu_insurance")}
              </Heading>
            )}
          </section>

          <Button
            type="button"
            onClick={handleClick}
            isLoading={isSubmitting}
            aria-disabled={isDisabledSubmit}
            adoptionClassName={classNames(styles.shoppingCartButton, {
              [styles.isDisabledSubmit]: isDisabledSubmit,
            })}
          >
            {t("details.summary.cta")}
          </Button>

          {hasSubmitError && (
            <p className={styles.errorMessage}>{errorMessage || t("common.error_message")}</p>
          )}
          {forceDisabledSubmit && (
            <p className={styles.errorMessage}>
              {t("details.summary.edit_pet_info_error.text")}
              <button type="button" onClick={handleEditPetInfoWarning}>
                {t("details.summary.edit_pet_info_error.link")}
              </button>
            </p>
          )}

          <section>
            {hasFakeChipNumber && (
              <Checkbox
                id={"chipRequestAccepted"}
                name={"chipRequestAccepted"}
                variantName="legalText"
                onChange={handleChipRequestAccepted}
                isChecked={isChipRequestAccepted}
                errorMessage={errorNoChipRequestSelected}
              >
                <span>{parse(t("details.summary.chip_request_consent"))}</span>
              </Checkbox>
            )}
            <Checkbox
              id={"generalTerms"}
              name={"generalTerms"}
              variantName="legalText"
              onChange={handleChangeGeneralTerms}
              isChecked={isGeneralTermsAccepted}
              errorMessage={errorNoGeneralTermsSelected}
            >
              {shouldShowAlternativeDisclaimer(result.country) ? (
                <span className="disclaimer">
                  {parse(
                    t("disclaimer.alternative.info", {
                      conditionsLink:
                        getDraftContractLink(result?.getQuote(healthProduct.id)?.uuid) ??
                        t("document.health.conditions.link"),
                    })
                  )}
                </span>
              ) : (
                <Disclaimer documentLinks={disclaimerDocumentLinks} />
              )}
            </Checkbox>
            <Checkbox
              id={"commercialAccepted"}
              name={"commercialAccepted"}
              variantName="legalText"
              onChange={handleChangeCommercialAccepted}
              isChecked={isCommercialAccepted}
            >
              <span>{t("marketing.consent.info")}</span>
            </Checkbox>
            <p
              className={styles.dataProtectionLegalText}
              dangerouslySetInnerHTML={{
                __html: t("data.protection.info", {
                  privacyUrl: `/${t("landing.modal.privacy-policy.slug")}`,
                  cookiesUrl: `/${t("document.cookies.slug")}`,
                }),
              }}
            ></p>
          </section>
        </div>
      )}
    </>
  );
};

export default ShoppingCart;
